.App-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightgray;
}

.App {
  text-align: center;
  width: 75%;
  background: white;
  
}
.home-page {
  box-shadow: 10px 5px 5px grey, -10px 5px 5px grey;
}
.header {
  display: flex;
  justify-content: center;
}

.riverview-acres-logo {
  margin: 0 25px;
}
.header-text {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 25px;

}

.nav-bar{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: lightblue;
}
.links {
  display: flex;
  justify-content: space-around;
  
}
.nav-link{
  font-size: 20px;
  font-weight: bold;
}

.alert {
  font-size: 25px;
  font-weight: bold;
}

.section {
  display: flex;
  margin: 0 5%;
}
.article {
  width: 40%;
  border: 3px solid;
  margin: 0 5px;
  padding: 8px;
  height: 60vh;
}
.article-header{
  font-size: 1.8em;
}
.article-content {
  font-size: 1.5em;
}

.img-gallary {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

img {
  height: 100%;
}

.top-img{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.katieSkeeter {
  height: 30vh;
}

.skeeterLookRight{
  height: 30vh;
  width: 60%;
  overflow: hidden;
}

.skeeterLookStraight {
  height: 30vh;
}

@media only screen and (max-width: 800px) {
  .App {
    width: 100%;
    overflow: hidden;
  }

  .App-wrapper,
  .header,
  .links,
  .section {
    flex-direction: column;
  }
  .section{
    margin: 0;
  }
  .img-gallary,
  .article {
    width: 100%;
  }

  .article {
    border-left: none;
    border-right: none;
    margin: 2% 0 ;
    padding: 0 ;
  }
}